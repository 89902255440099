import React from 'react';
import { Link } from 'gatsby';
import Seo from "../components/seo";
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';
import '../styles/fonts.css';

const toolGroups = [
  {
    name: "Image Conversion",
    tools: [
      { 
        name: 'Image to JPEG Converter', 
        description: 'Convert HEIC, HEIF, and PNG images to JPEG format', 
        path: '/tools/heic-to-jpeg-converter' 
      },
      { 
        name: 'SVG to JPEG/PNG Converter', 
        description: 'Convert SVG images to JPEG or PNG format', 
        path: '/tools/svg-to-jpeg-png-converter' 
      },
      {
        name: 'Image Inpainting Tool',
        description: 'Remove or fix unwanted elements in images',
        path: '/tools/image-inpainting'
      },
      {
        name: 'CSV Viewer',
        description: 'View CSV files in a table format',
        path: '/tools/csv-viewer'
      },
    ]
  },
  {
    name: "Text Tools",
    tools: [
      {
        name: 'Word Counter',
        description: 'Count words in your text',
        path: 'https://www.nombredemots.com/'
      },
    ]
  }
];

const ToolsPage = () => {
  return (
    <>
      <Navigation />
      <div className="min-h-screen pb-12 pt-6 font-serif bg-white">
        <div className="max-w-7xl mx-auto px-4 md:px-6">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
            <main className="lg:col-span-8 lg:col-start-1">
              <h2 className="text-4xl text-gray-900 mb-12">Tools</h2>
              <div className="space-y-16">
                {toolGroups.map((group, groupIndex) => (
                  <div key={groupIndex}>
                    <h3 className="text-2xl text-brand-600 mb-6">{group.name}</h3>
                    <ul className="space-y-6">
                      {group.tools.map((tool, toolIndex) => (
                        <li key={toolIndex} className="border-b border-gray-100 pb-6 last:border-b-0">
                          {tool.path.startsWith('http') ? (
                            <a 
                              href={tool.path} 
                              className="text-xl text-gray-900 hover:text-brand-600 block mb-2"
                              target="_blank" 
                              rel="noopener noreferrer"
                            >
                              {tool.name}
                            </a>
                          ) : (
                            <Link 
                              to={tool.path} 
                              className="text-xl text-gray-900 hover:text-brand-600 block mb-2"
                            >
                              {tool.name}
                            </Link>
                          )}
                          <p className="text-gray-600 leading-relaxed">{tool.description}</p>
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </main>

            {/* Sidebar */}
            <aside className="lg:col-span-3 lg:col-start-10">
              <div className="sticky top-8 space-y-8">
                {/* Tool Categories */}
                <div className="border-l-4 border-brand-200 pl-4">
                  <h2 className="text-xl font-bold text-gray-900 mb-4">Categories</h2>
                  <div className="space-y-3">
                    {toolGroups.map((group, index) => (
                      <a 
                        key={index}
                        href={`#${group.name.toLowerCase().replace(/\s+/g, '-')}`}
                        className="block text-gray-700 hover:text-brand-600"
                      >
                        {group.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ToolsPage;